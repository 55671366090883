import React, { useState, useEffect } from 'react';
import {
  Center,
  Heading,
  Container,
  Box,
  OrderedList,
  ListItem,
  IconButton,
  Text,
  Flex,
  Spacer,
  VStack,
  Stack,
  AspectRatio,
} from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react"
import { Route } from 'react-router-dom';
import { ExternalLinkIcon, DownloadIcon, ViewIcon } from '@chakra-ui/icons'
import { PageHeader } from '../components/PageHeader';

export const DownloadItem = (text, aria, isLoaded, link) => {
  return (
    <ListItem>
        <Flex>
          <Text fontSize={{base:"xl", lg:"lg"}}>{ text }</Text>
          <Spacer/>
          <IconButton
            aria-label={ aria }
            icon={<DownloadIcon/>}
            variant="outline"
            as="a"
            isLoading = { !isLoaded }
            href={ link }/>
        </Flex>
    </ListItem>
  );
};

export const ExternalLinkItem = (text, aria, isLoaded, link) => {
  return (
    <ListItem>
        <Flex>
          <Text fontSize={{base:"xl", lg:"lg"}}>{ text }</Text>
          <Spacer/>
          <IconButton
            aria-label={ aria }
            icon={<ExternalLinkIcon/>}
            variant="outline"
            as="a"
            isLoading = { !isLoaded }
            target="_blank"
            href={ link }/>
        </Flex>
    </ListItem>
  );
};

export const ModalItem = (text, aria, isLoaded, onOpen, link) => {
  return (
    <ListItem>
        <Flex>
          <Text fontSize={{base:"xl",lg:"lg"}}>{ text }</Text>
          <Spacer/>
          <IconButton
            aria-label={ aria }
            icon={<ViewIcon/>}
            variant="outline"
            as="a"
            isLoading = { !isLoaded }
            onClick={onOpen}/>
        </Flex>
    </ListItem>
  );
};

export const GettingStarted = (rows) => {

  const [purchaseListLink, setPurchaseListLink] = useState("")
  const [modelsLink, setModelsLink] = useState("")
  const [assemblyLink, setAssemblyLink] = useState("")
  const [assemblyVideo0, setAssemblyVideo0] = useState("")
  const [assemblyVideo1, setAssemblyVideo1] = useState("")
  const [tutorialsLink, setTutorialsLink] = useState("")
  const [softwareLink, setSoftwareLink] = useState("")
  const [isLoaded, setIsLoaded] = useState(false);
  const {isOpen, onOpen, onClose} = useDisclosure()

  useEffect(() => {
    if (typeof rows !== 'undefined') {
      setPurchaseListLink(rows[0].Link)
      setModelsLink(rows[1].Link)
      setAssemblyLink(rows[2].Link)
      setAssemblyVideo0(rows[3].Link)
      setAssemblyVideo1(rows[4].Link)
      setTutorialsLink(rows[5].Link)
      setSoftwareLink(rows[6].Link)
      setIsLoaded(true);
    }
  }, [rows]);

  return (
    <Route
      exact path='/getting_started'
      render={() => {
        return (
          <>
            <Center marginTop={10} marginBottom={10}>
              <VStack>
                {PageHeader("Getting Started",
                            `To help you get started with acquiring your own
                            SVEA platform, we have provided a purchase list,
                            all the required 3D models, assembly instructions,
                            some basic tutorials, and a software 'starter kit'.`
                )}

                <Stack w={{base: "75%", lg: "95%"}} align="center" spacing={10} direction={{ base:"column", lg:"row" }}>
                  <Container p={6} shadow="md" borderWidth="1px" maxWidth={{base: "80ch", lg: "40ch"}}>
                      <Heading marginBottom={8} fontSize={{base:"3xl", lg:"2xl"}}>
                        Resources
                      </Heading>
                      <OrderedList spacing={2}>
                        {DownloadItem("Purchase List Link", "Download Purchase List", isLoaded, purchaseListLink)}
                        {DownloadItem("Download 3D Models", "Download 3D Models", isLoaded, modelsLink)}
                        {DownloadItem("Assembly Instructions", "Download Assembly Instructions", isLoaded, assemblyLink)}
                        {ModalItem("Watch Assembly Videos","Open Assembly Video Viewer",isLoaded,onOpen)}
                        {ExternalLinkItem("Tutorials", "Go to SVEA tutorials", isLoaded, tutorialsLink)}
                        {ExternalLinkItem("Software", "Go to SVEA software library", isLoaded, softwareLink)}
                      </OrderedList>
                  </Container>

                  <AspectRatio width="100%" ratio={16 / 9}>
                    <iframe
                      title="SVEA Sample"
                      src="https://www.youtube.com/embed/m78GL19xXgk?start=20&end=43&autoplay=1&modestbranding=1&controls=0&loop=1&mute=1"
                      allowFullScreen
                    />
                  </AspectRatio>
                </Stack>
              </VStack>
            </Center>

            <Modal size="4xl" scrollBehavior="inside" isOpen={isOpen} onClose={onClose}>
              <ModalOverlay/>
              <ModalContent>
                <ModalHeader>Assembly Video</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                  <Box>
                    <AspectRatio width="100%" ratio={16 / 9}>
                      <iframe
                        title="assembly0"
                        src={assemblyVideo0}
                        allowFullScreen
                      />
                    </AspectRatio>
                  </Box>
                  <Box>
                    <AspectRatio width="100%" ratio={16 / 9}>
                      <iframe
                        title="assembly1"
                        src={assemblyVideo1}
                        allowFullScreen
                      />
                    </AspectRatio>
                  </Box>
                </ModalBody>
              </ModalContent>
            </Modal>
          </>
      )}}
    />
  );
}
