import React from 'react';
import {
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Link,
  useColorModeValue,
} from '@chakra-ui/react';
import { NavLink } from "react-router-dom";
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons'
import { ColorModeSwitcher} from './ColorModeSwitcher';

export const HamburgerMenu = props => {
  const bg_color = useColorModeValue("white", "black")
  return (
    <Menu colorScheme>
      {({ isOpen  }) => (
        <>
          <MenuButton
            isActive={isOpen}
            as={IconButton}
            icon={isOpen? <CloseIcon w={6} h={6}/> : <HamburgerIcon w={6} h={6}/>}
            size="lg"
            marginRight="6"
            variant="solid"
          />
          <MenuList bg={bg_color}>
            <Link as={NavLink} to="/" exact style={{ textDecoration: 'none'}}>
              <MenuItem>
                Home
              </MenuItem>
            </Link>
            <Link as={NavLink} to="/getting_started" exact style={{ textDecoration: 'none'}}>
              <MenuItem>
                Getting Started
              </MenuItem>
            </Link>
            <Link as={NavLink} to="/projects" exact style={{ textDecoration: 'none'}}>
              <MenuItem>
                Projects
              </MenuItem>
            </Link>
            <Link as={NavLink} to="/publications" exact style={{ textDecoration: 'none'}}>
              <MenuItem>
                Publications
              </MenuItem>
            </Link>
            <Link as={NavLink} to="/about" exact style={{ textDecoration: 'none'}}>
              <MenuItem>
                About
              </MenuItem>
            </Link>
            <MenuItem>
                <ColorModeSwitcher/>
            </MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  )
};
