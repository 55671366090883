import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ChakraProvider, Box, extendTheme } from '@chakra-ui/react';
import { mode, Styles } from '@chakra-ui/theme-tools';
import { Global, css } from '@emotion/react'

import { NavBar } from './components/NavBar';
import { Landing } from './layouts/Landing';
import { GettingStarted } from './layouts/GettingStarted';
import { Projects } from './layouts/Projects';
import { Publications } from './layouts/Publications';
import { About } from './layouts/About';

import { GoogleSpreadsheet } from "google-spreadsheet";
const SPREADSHEET_ID = process.env.REACT_APP_SPREADSHEET_ID;
const GETTING_STARTED_SHEET_ID = process.env.REACT_APP_GETTING_STARTED_SHEET_ID;
const PROJECTS_SHEET_ID = process.env.REACT_APP_PROJECTS_SHEET_ID;
const PUBLICATIONS_SHEET_ID = process.env.REACT_APP_PUBLICATIONS_SHEET_ID;
const CURRENT_MEMBERS_SHEET_ID = process.env.REACT_APP_CURRENT_MEMBERS_SHEET_ID;
const PREVIOUS_MEMBERS_SHEET_ID = process.env.REACT_APP_PREVIOUS_MEMBERS_SHEET_ID;
const CLIENT_EMAIL = process.env.REACT_APP_GOOGLE_CLIENT_EMAIL;
const PRIVATE_KEY = process.env.REACT_APP_GOOGLE_SERVICE_PRIVATE_KEY;

const doc = new GoogleSpreadsheet(SPREADSHEET_ID);
var docIsInit = false;
var getStartLoaded = false;
var projLoaded = false;
var pubLoaded = false;
var currMemLoaded = false;
var prevMemLoaded = false;

var savedGetStartRows;
var savedProjRows;
var savedPubRows;
var savedCurrMemRows;
var savedPrevMemRows;

const getRows = async (sheet_id, maxRows) => {
  try {
    if (!docIsInit) {
      await doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY,
      });
      await doc.loadInfo();
      docIsInit = true;
    }
    const sheet = doc.sheetsById[sheet_id];
    const rows = await sheet.getRows({limit: maxRows});
    return rows
  } catch (e) {
    console.error('Trouble reaching sheets');
  }
};

const colors = {
  bw: {
    50: '#f2f2f8',
    100: '#d7d9db',
    200: '#bebfc0',
    300: '#a4a5a6',
    400: '#8a8b8d',
    500: '#717274',
    600: '#57595b',
    700: '#3e4041',
    800: '#242628',
    900: '#040f0f',
  },
}
const GlobalStyles = css`
  .js-focus-visible :focus:not([data-focus-visible-added]) {
     outline: none;
     box-shadow: none;
   }
`;

const styles: Styles = {
  global: (props) => ({
    body: {
      bg: mode('white', 'black')(props),
      color: mode('black', 'white')(props),
    }
  })
}
const theme = extendTheme({colors, styles})

function App() {

  // start loading in google sheet resources in background
  const [getStartRows, setGetStartRows] = useState(savedGetStartRows);
  if (!getStartLoaded){
    getRows(GETTING_STARTED_SHEET_ID).then((result) =>{
      if (typeof result !== 'undefined') {
        setGetStartRows(result)
        savedGetStartRows = result;
        getStartLoaded = true;
      }});
  }

  const [projRows, setProjRows] = useState(savedProjRows);
  if (!projLoaded){
    getRows(PROJECTS_SHEET_ID).then((result) =>{
      if (typeof result !== 'undefined') {
        setProjRows(result)
        savedProjRows = result;
        projLoaded = true;
      }});
  }

  const [pubRows, setPubRows] = useState(savedPubRows)
  if (!pubLoaded){
    getRows(PUBLICATIONS_SHEET_ID).then((result) =>{
      if (typeof result !== 'undefined') {
        setPubRows(result)
        savedPubRows = result;
        pubLoaded = true;
      }});
  }

  const [currMemRows, setCurrMemRows] = useState(savedCurrMemRows)
  if (!currMemLoaded){
    getRows(CURRENT_MEMBERS_SHEET_ID).then((result) =>{
      if (typeof result !== 'undefined') {
        setCurrMemRows(result)
        savedCurrMemRows = result;
        currMemLoaded = true;
      }});
  }
  const [prevMemRows, setPrevMemRows] = useState(savedPrevMemRows)
  if (!prevMemLoaded){
    getRows(PREVIOUS_MEMBERS_SHEET_ID).then((result) =>{
      if (typeof result !== 'undefined') {
        setPrevMemRows(result.reverse())
        savedPrevMemRows = result.reverse();
        prevMemLoaded = true;
      }});
  }

  return (
    <ChakraProvider theme={theme}>
      <Global styles={GlobalStyles} />
      <Router>
        <NavBar/>
        <Box>
          <Switch>
            <Route exact path='/' component={Landing} />
            {GettingStarted(getStartRows)}
            {Projects(projRows)}
            {Publications(pubRows)}
            {About(currMemRows, prevMemRows)}
          </Switch>
        </Box>
      </Router>
    </ChakraProvider>
  );
}

export default App;
