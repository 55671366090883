import React from 'react';
import {Image, useColorModeValue} from '@chakra-ui/react';
import svea_logo_b from './SVEA_logo_black.svg';
import svea_logo_w from './SVEA_logo_white.svg';

export const Logo = props => {
  //const gradient = useColorModeValue("linear(to-b, #040f0f, #a4a5a6)",
                                     //"linear(to-b, #f2f2f8, #57595b)")
    //<Box marginLeft="4">
      //<Text
        //bgGradient={gradient}
        //bgClip="text"
        //fontSize="6xl"
        //fontWeight="extrabold"
        //{...props}
      //>
        //SVEA
      //</Text>
    //</Box>
  const svea_logo = useColorModeValue(svea_logo_b, svea_logo_w)
  return (
    <Image h="14" src={ svea_logo } />
  )
};
