import React from 'react';
import { Container, Heading, Text} from '@chakra-ui/react';

export const PageHeader = (header, body) => {
  return (
    <Container marginBottom={8} maxWidth="100ch">
      <Heading marginBottom={4} fontSize={{base:"3xl", lg:"4xl"}}>
        { header }
      </Heading>
      <Text>
        { body }
      </Text>
    </Container>
  )
};
