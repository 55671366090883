import React from 'react';
import {
  IconButton,
  Text,
  Heading,
  Stack,
  HStack,
  VStack,
  Image,
  Center,
  Container,
  Tooltip,
} from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/react';
import svea_b from './media/svea_b.svg';
import svea_w from './media/svea_w.svg';
import { CopyIcon, DownloadIcon } from '@chakra-ui/icons'

export const Landing = props => {
  const svea = useColorModeValue(svea_b, svea_w)
  const svea_size = useColorModeValue("400px", "400px")

  return (
    <Stack spacing={8}>

      <Center>
        <Stack align="center" direction={{ base:"column", lg:"row" }}>
          <Image boxSize={ svea_size } src={ svea } />
          <Container p={5}>
            <Text fontSize={{base: "xl", sm: "2xl", md: "3xl"}}>
              The [s]mall [ve]hicles for [a]utonomy platform designed for large-scale transportation experiments
            </Text>
          </Container>
        </Stack>
      </Center>

      <Center>
        <HStack>
          <Container
            marginLeft={4}
            marginRight={4}
            marginBottom={4}
            p={5}
            shadow="md"
            borderWidth="1px"
            maxWidth={{base:"60ch", lg: "90ch"}}
          >
            <Heading fontSize="xl" marginBottom={2}>To learn more:</Heading>
            <Text mt={4} fontSize={{base:"sm", lg:"lg"}} as="cite">
              Jiang, F. J., Al-Janabi, M., Bolin, T., Johansson, K. H., Martensson, J. (2022).
              SVEA: an experimental testbed for evaluating V2X use-cases. (available soon)
            </Text>
          </Container>
        </HStack>
      </Center>
    </Stack>
  );
}
