import React, { useState, useEffect } from 'react';
import {
  Box,
  Badge,
  Heading,
  Text,
  Image,
  IconButton,
  Center,
  Spinner,
  Container,
  Flex,
  Spacer,
  Stack,
  SimpleGrid,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,

} from '@chakra-ui/react';
import { Route } from 'react-router-dom';
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { PageHeader } from '../components/PageHeader';

export const MemItems = (rows) => {
  return rows.map((row) =>
      <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
        <Image width="100%" src={row.Picture}/>
        <Box p="4">
          <Flex align="center">
            <Heading fontSize={{base:"md", lg:"lg"}}>
              {row.Name}
            </Heading>
            <Spacer/>
            <IconButton
              aria-label="Find out more"
              icon={<ExternalLinkIcon/>}
              variant="ghost"
              size="sm"
              as="a"
              target="_blank"
              href={ row.Link }/>
          </Flex>


          <Text size="lg" marginBottom={2}>
            {row.WorkingOn}
            {row.WorkedOn}
          </Text>

          <Box d="flex" alignItems="right">
            <Badge borderRadius="full" fontSize="0.4em" px="2" colorScheme="bw">
              { row.Position }
            </Badge>
          </Box>
        </Box>
      </Box>
  );
}

export const NoPhotoMemItems = (rows) => {
  return rows.map((row) =>
      <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
        <Box p="4">
          <Flex align="center">
            <Heading fontSize={{base:"md", lg:"lg"}}>
              {row.Name}
            </Heading>
            <Spacer/>
            <IconButton
              aria-label="Find out more"
              icon={<ExternalLinkIcon/>}
              variant="ghost"
              size="sm"
              as="a"
              target="_blank"
              href={ row.Link }/>
          </Flex>


          <Text size="lg" marginBottom={2}>
            {row.WorkingOn}
            {row.WorkedOn}
          </Text>

          <Box d="flex" alignItems="right">
            <Badge borderRadius="full" fontSize="0.4em" px="2" colorScheme="bw">
              { row.Position }
            </Badge>
          </Box>
        </Box>
      </Box>
  );
}

export const About = (currMemRows, prevMemRows) => {
  const [currMemItems, setCurrMemItems] = useState(
    <Container>
      <Center>
        <Spinner thickness="6px" speed="1.0s" emptyColor="bw.200" color="bw.500" size="xl"/>
      </Center>
    </Container>);
  const [prevMemItems, setPrevMemItems] = useState(
    <Container>
      <Center>
        <Spinner thickness="6px" speed="1.0s" emptyColor="bw.200" color="bw.500" size="xl"/>
      </Center>
    </Container>);

  useEffect(() => {
    if (typeof currMemRows !== 'undefined') {
      setCurrMemItems(MemItems(currMemRows))
    }
  }, [currMemRows]);

  useEffect(() => {
    if (typeof prevMemRows !== 'undefined') {
      setPrevMemItems(NoPhotoMemItems(prevMemRows))
    }
  }, [prevMemRows]);

  return (
    <Route
      exact path='/about'
      render={() => {
        return (
          <Center marginTop={10} marginBottom={10}>
            <Stack spacing={25} width="100%" direction="column">
              <Center marginLeft={10} marginRight={10}>
                {PageHeader("About",
                            `The SVEA platform is developed in the Smart Mobility
                             Lab at the KTH Royal Institute of Technology, Stockholm,
                             Sweden. It's supported by a collaborative effort between
                             transportation, robotics, and control researchers.`
                )}
              </Center>

              <Center>
                <Accordion width="70%" defaultIndex={[0]} allowToggle>
                  <AccordionItem>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        <Heading marginBottom={4} fontSize={{base:"lg", lg:"xl"}}>
                          Current Contributors
                        </Heading>
                      </Box>
                      <AccordionIcon/>
                    </AccordionButton>
                    <AccordionPanel>
                      <Center>
                        <SimpleGrid columns={{base: 1, md: 2, lg: 3}} spacing={10} marginTop={4} marginBottom={10}>
                          { currMemItems }
                        </SimpleGrid>
                      </Center>
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        <Heading marginBottom={4} fontSize={{base:"lg", lg:"xl"}}>
                          Previous Contributors
                        </Heading>
                      </Box>
                      <AccordionIcon/>
                    </AccordionButton>
                    <AccordionPanel>
                      <Center>
                        <SimpleGrid columns={{base: 1, md: 2, lg: 3}} spacing={10} marginTop={4} marginBottom={10}>
                          { prevMemItems }
                        </SimpleGrid>
                      </Center>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Center>
            </Stack>
          </Center>
        )
      }}
    />
  );
}
