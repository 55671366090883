import React from 'react';
import { Link, Flex, Box, Spacer} from '@chakra-ui/react';
import { useBreakpointValue } from '@chakra-ui/react';
import { NavLink } from "react-router-dom";
import { ExpandedMenu } from './ExpandedMenu';
import { HamburgerMenu } from './HamburgerMenu';
import { Logo } from './Logo';

export const AutoMenu = (): ReactElement => {
  const hamburgerMenuOrNot = useBreakpointValue({ base: <HamburgerMenu/>, lg: <ExpandedMenu/> })
  return <>{hamburgerMenuOrNot}</>
}

export const NavBar = props => {
  return (
    <Flex align="center">
      <Box p={6}>
        <Link as={NavLink} to="/" exact style={{ textDecoration: 'none'}}><Logo/></Link>
      </Box>
      <Spacer/>
      <AutoMenu/>
    </Flex>
  )
};
