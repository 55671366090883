import React, { useState, useEffect } from 'react';
import {
  Center,
  SimpleGrid,
  Container,
  Box,
  Image,
  Heading,
  Badge,
  Spinner,
  AspectRatio,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from '@chakra-ui/react';
import { Route } from 'react-router-dom';

export const ProjItems = (rows) => {
  return rows.map((row) =>
      <Box maxW="md" borderWidth="1px" borderRadius="lg" overflow="hidden">
        <Image src={row.Thumbnail} alt={row.Title} />
        <Box p="6">
          <Box d="flex" alignItems="baseline">
            <Badge borderRadius="full" px="2" colorScheme="bw">
              { row.CurrentorPast }
            </Badge>
            <Box
              color="bw.500"
              fontWeight="semibold"
              letterSpacing="wide"
              fontSize="xs"
              textTransform="uppercase"
              ml="2"
            >
              {row.Start} {(row.End === '') ? '' : ['',row.End].join(' \u2022 ')}
            </Box>
          </Box>
          <Box
            mt="1"
            fontWeight="semibold"
            as="h4"
            lineHeight="tight"
            isTruncated
          >
            {row.Title}
          </Box>
          <Box>
            { row.Description }
          </Box>
        </Box>
      </Box>
  );
}

export const Projects = (rows) => {
  const [projItems, setProjItems] = useState(
    <Container marginTop={40}>
      <Center>
        <Spinner thickness="6px" speed="1.0s" emptyColor="bw.200" color="bw.500" size="xl"/>
      </Center>
    </Container>);

  useEffect(() => {
    if (typeof rows !== 'undefined') {
      setProjItems(ProjItems(rows))
    }
  }, [rows]);

  return (
    <Route
      exact path='/projects'
      render={() => {
        return (
          <Center marginTop={10} marginBottom={10}>
            <AspectRatio width="80%" ratio={16 / 9}>
              <iframe
                title="Project Videos"
                src="https://youtube.com/embed/videoseries?list=PLSjh9dNEkl099lniHGCimlHA7LsiqpdOL"
                frameborder="0"
                allowFullScreen
              />
            </AspectRatio>
          </Center>
        )
      }}
    />
  );
}
