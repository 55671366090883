import React, { useState, useEffect } from 'react';
import {
  Center,
  Container,
  Text,
  Spinner,
  UnorderedList,
  ListItem,
  HStack,
  IconButton,
  Tooltip,
} from '@chakra-ui/react';
import { Route } from 'react-router-dom';
import { CopyIcon } from '@chakra-ui/icons'

export const PubItems = (rows) => {
  return rows.reverse().map((row) =>
    <ListItem>
      <HStack spacing={4}>
        <Text fontSize={{base:"xs", md:"sm", lg:"md"}}>{ row.Formatted }</Text>
        <Tooltip label="Copy BibTex" fontsize="sm" placement="right">
          <IconButton
            aria-label= "Copy BibTex citation"
            icon={<CopyIcon/>}
            variant="outline"
            as="a"
            onClick={() => navigator.clipboard.writeText(row.Bibtex)}
          />
        </Tooltip>
      </HStack>
    </ListItem>
  )}

export const Publications = (rows) => {
  const [pubItems, setPubItems] = useState(
    <Container marginTop={40}>
      <Center>
        <Spinner thickness="6px" speed="1.0s" emptyColor="bw.200" color="bw.500" size="xl"/>
      </Center>
    </Container>);

  useEffect(() => {
    if (typeof rows !== 'undefined') {
      setPubItems(PubItems(rows))
    }
  }, [rows]);

  return (
    <Route
      exact path='/publications'
      render={() => {
        return (
          <Center marginTop={20}>
            <Container maxWidth="80ch" marginLeft={4} marginRight={4} marginBottom={10}>
              <UnorderedList spacing={4}>
                { pubItems }
              </UnorderedList>
            </Container>
          </Center>
        )
      }}
    />
  );
}
