import React from 'react';
import { Flex, Box, Button, Link} from '@chakra-ui/react';
import { NavLink } from "react-router-dom";
import { ColorModeSwitcher} from './ColorModeSwitcher';

export const ExpandedMenu = props => {
  const buttonSpace = 0
  const buttonType = "ghost"

  return (
    <Flex>
      <Box p="1">
        <Link as={NavLink} to="/" exact style={{ textDecoration: 'none'}}>
          <Button marginTop={buttonSpace} colorScheme="bw" size="lg" variant={buttonType}>
            Home
          </Button>
        </Link>
      </Box>
      <Box p="1">
        <Link as={NavLink} to="/getting_started" exact style={{ textDecoration: 'none'}}>
          <Button marginTop={buttonSpace} colorScheme="bw" size="lg" variant={buttonType}>
            Getting Started
          </Button>
        </Link>
      </Box>
      <Box p="1">
        <Link as={NavLink} to="/projects" exact style={{ textDecoration: 'none'}}>
          <Button marginTop={buttonSpace} colorScheme="bw" size="lg" variant={buttonType}>
            Projects
          </Button>
        </Link>
      </Box>
      <Box p="1">
        <Link as={NavLink} to="/publications" exact style={{ textDecoration: 'none'}}>
          <Button marginTop={buttonSpace} colorScheme="bw" size="lg" variant={buttonType}>
            Publications
          </Button>
        </Link>
      </Box>
      <Box p="1">
        <Link as={NavLink} to="/about" exact style={{ textDecoration: 'none'}}>
          <Button marginTop={buttonSpace} colorScheme="bw" size="lg" variant={buttonType}>
            About
          </Button>
        </Link>
      </Box>
      <Box p="1">
        <ColorModeSwitcher marginTop={buttonSpace+1} marginLeft="4" marginRight="6" variant="solid"/>
      </Box>
    </Flex>
  )
};
